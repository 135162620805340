@import 'styles/global.scss';

.panelBody {
    @include centerBoth;
}

.programOverviewPanel {
    align-content: center;
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}
