@import 'styles/global.scss';

.barListItem {
    align-items: center;
    background-color: $barListItemBackgroundColor;
    border-left-style: solid;
    border-left-width: 4px;
    border-color: $barListItemBorderColor;
    display: grid;
    gap: 15px;
    grid-template-rows: 20px;
    padding: 15px 16px 15px 21px;

    &:hover {
        background-color: $barListItemActiveBackgroundColor;
        border-color: $barListItemActiveBorderColor;
        box-shadow: 0px 2px 5px $barListItemActiveShadowColor;
    }
}

.isActive {
    background-color: $barListItemActiveBackgroundColor;
    border-color: $barListItemActiveBorderColor;
    box-shadow: 0px 2px 5px $barListItemActiveShadowColor;
}
