@import 'styles/global.scss';

.bodyText {
    white-space: initial;
}

.panelBody {
    grid-template-rows: 60px auto auto auto;
    text-align: center;
}

.span {
    color: $highlightTextColor;
}
