@import 'styles/global.scss';

.container {
    grid-column: 1/-1;
}

.panelBody {
    grid-template-columns: 1fr max-content;
    grid-template-rows: min-content;
}

.title {
    grid-column: 1/-1;
    grid-row: 1/2;
}

.versionTags {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: end;
}
