@import 'styles/global.scss';

.editPerson {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: 30% auto 70px 70px 70px;
    grid-template-rows: 55px auto;
    height: 100%;
    width: 100%;
}

.cancelButton {
    grid-column: 3/4;
}

.inviteButton {
    grid-column: 4/5;
}

.pageTitle {
    grid-column: 1/2;
}

.personalInformationPanel {
    grid-column: 2/6;
}

.saveButton {
    grid-column: 5/6;
}

.uploadPhotoPanel {
    grid-column: 1/2;
    grid-row: 2/3;
}
