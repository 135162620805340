@import 'styles/global.scss';

.riskPill {
    grid-row: 2/3;
}

.withLabel {
    display: grid;
    grid-template-columns: max-content 1fr;
}
