@import 'styles/global.scss';

.toggleContainer {
    align-content: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 20px 20px auto;
    height: fit-content;
}

.toggleText {
    color: $inactiveTextColor;
    text-transform: capitalize;
}

.toggleIsActive {
    color: $activeColor;
}

.knob {
    background-color: $lightMediumGray;
    border-radius: 10px;
    box-shadow: 0px 1px 2px $slideToggleKnobShadowColor;
    height: 16px;
    left: -1px;
    top: 3;
    position: relative;
    transition-delay: 0;
    transition-duration: 0.5s;
    transition-property: left;
    width: $smallIconSize;

    &:hover {
        cursor: pointer;
    }

    &.isActive {
        left: calc(50% + 1px);
        background-color: $slideToggleKnobColor;
    }

    &.isActiveOrange {
        left: calc(50% + 1px);
        background-color: $orange;
    }
}

.slideToggle {
    @include normalFont;

    align-items: center;
    background-color: $slideToggleBackgroundColor;
    border-radius: 7px;
    color: $bodyTextColor;
    display: flex;
    height: 12px;
    margin-top: 3px;
    width: 100%;

    &.isActive {
        left: calc(50% + 1px);
        background-color: $slideToggleBackgroundGreen;
    }

    &.isActiveOrange {
        left: calc(50% + 1px);
        background-color: $lightOrange;
    }
}
