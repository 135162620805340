@import 'styles/global.scss';

.panelBody {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 58px auto;
    height: auto;
}

.table {
    grid-column: 1/8;
}

.dropdown, .startDate, .endDate {
    height: fit-content;
}

.searchRowContainer{
    display: flex;
    align-items: center;
    gap: 10px;
    grid-column: 1/8;
}

.search{
    width: 100%;
}
