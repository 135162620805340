@import 'styles/global.scss';

.bodyText {
    margin: 0 auto;
    max-width: 400px;
    white-space: initial;
}

.panelBody {
    grid-template-rows: 80px auto auto auto;
    text-align: center;
}
