@import 'styles/global.scss';

.topRow {
    align-items: center;
    display: flex;
    grid-column: 1/3;
    justify-content: space-between;
}

.iconsContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 10px 0 0;
    width: 62px;
}

.deleteIcon,
.editIcon {
    cursor: pointer;
    height: 26px;
    padding: 5px 0;
    width: 26px;
}

.bodyText {
    grid-column: 2/3;
    margin-bottom: 5px;
}

.editContainer {
    grid-column: 2/3;
    margin: 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.iconsEditContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 10px 0 0;
    width: 62px;
}

.input {
    border: 1px solid $inputBorderColor;
    width: 100%;
}

.comment {
    align-items: center;
    display: grid;
    grid-template-columns: 45px 1fr;
    margin-bottom: 20px;

    &:not(:hover) {
        .iconsContainer {
            display: none;
        }
    }
}

.content {
    grid-column: 2/3;
}
