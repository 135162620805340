@import 'styles/global.scss';


input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tabs {
  border: 2px solid $lightMediumGray;
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 100%;
  overflow: hidden;
  &Label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }
  &Content {
    max-height: 0;
    padding: 0 1em;
    transition: all .35s;
  }
  &Close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
}

// :checked
input:checked {
  + .tabLabel {
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tabContent {
    max-height: 100vh;
    padding: 1em;
  }
}

