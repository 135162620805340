@import 'styles/global.scss';

.avatar {
    align-items: center;
    background-color: $avatarBackgroundColor;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    grid-column: 1/3;
    grid-row: 1/3;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.avatarContainer{
    align-items: center;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 60% 40%;
    height: 100%;
    position: relative;
    width: 100%;
}

.image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
    z-index: 1;
}

.indicator {
    border-radius: 50%;
    grid-column: 2/3;
    grid-row: 2/3;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 2;
}

.indicator span {
    color: $white;
    font-size:80%;
    font-weight: 900;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.positiveIndicator{
    background-color: $green;
}

.negativeIndicator{
    background-color: $red;
}

.warningIndicator{
    background-color: $orange;
}

.neutralIndicator{
    background-color: transparent;
}
