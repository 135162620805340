@import 'styles/global.scss';

.panelBody {
    grid-template-columns: 1fr 1fr 2fr 25px;
    grid-template-rows: 56px auto;
}

.table {
    grid-column: 1 / 5;
}
