@import 'styles/global.scss';

.overdue {
    &:hover {
        cursor: auto;
        opacity: 100%;
    }

    p {
        color: $negativeTextColor;
    }
}
