@import 'styles/global.scss';

.contentBox {
    grid-column: 1/5;
}

.date {
    grid-column: 4/5;
}

.panelBody {
    display: grid;
    gap: 14px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content min-content min-content minmax(
            100px,
            max-content
        );
}

.title {
    grid-column: 1/5;
}
