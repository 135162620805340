@import 'styles/global.scss';

.arrow {
    margin-left: 5px;
    visibility: hidden;

    &.visibleArrow {
        opacity: 1 !important;
        visibility: visible;
    }
}

.clickableCell {
    justify-content: flex-start;

    &:hover .arrow {
        opacity: 0.5;
        visibility: visible;
    }
}

.tableHeader {
    background-color: $tableHeaderBackgroundColor;
    grid-template-rows: 20px;
}
