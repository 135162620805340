@import 'styles/global.scss';

.image {
    border-radius: $borderRadius;
    filter: brightness(0.8);
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}
