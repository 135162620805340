@import 'src/styles/global.scss';

.label {
    cursor: pointer;
}

.radioButtonWithLabel {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    margin-right: 10px;
    margin-left: 0;
}
