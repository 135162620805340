@import 'styles/global.scss';

.formsListItem {
    border: none;
    cursor: auto;
    display: grid;
    grid-template-columns: 1fr 140px 1fr;

    &:hover {
        box-shadow: none;
    }
}

.isActive {
    box-shadow: none;
}
