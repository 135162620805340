@import 'styles/global.scss';

.drawer {
    background-color: $drawerBackgroundColor;
    box-shadow: -4px 0px 24px $drawerShadowColor;
    overflow-y: auto;
    max-width: 100vw;

    &.left {
        height: 100%;
        width: 400px;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
    }

    &.right {
        height: 100%;
        width: 400px;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    &.bottom {
        height: 95vh;
        width: 100%;
        transform: translateY(100%);
        transition: transform 0.6s ease-in-out;
    }

    &.isOpen {
        &.right, &.left{
            transform: translateX(0);
        }
        &.bottom{
            transform: translateY(0)
        }
    }
}

.overlay {
		z-index: 10;
    &.left {
        transition: visibility 0.3s;
        justify-content: flex-start;
    }

    &.right {
        transition: visibility 0.3s;
        justify-content: flex-end;
    }

    &.bottom {
        transition: visibility 0.6s;
    }
}
