.activitiesStatistics {
    grid-row: 2/3;
    height: 100%;
    display: grid;
    gap: 10px;
}

.activitiesStatisticsContainer {
    height: 100%;
    display: grid;
    gap: 10px;
    grid-template-rows: max-content 1fr;
}

.activitiesStatisticsHeader {
    font-weight: 400;
    grid-row: 1/2;
}
