.addActivityQuestions {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.input {
    grid-column: 1 / 4;
}

.title {
    grid-column: 1 / 4;
}

.infoHollowButton {
    grid-column: 1 / 2;
}

.saveButton {
    grid-column: 1 / 4;
}

.saveAndCloseButton {
    grid-column: 3 / 4;
}

.dynamicEditor {
    grid-column: 1/4;
}

.formField {
    grid-column: 1/4;
}
