$desktopWidth: 1440px;
$mobileWidth: 1000px;
$tabletWidth: 1200px;

@mixin desktopAndAbove {
    @media (min-width: #{ $tabletWidth + 1px }) {
        @content;
    }
}

@mixin desktopOnly {
    @media (min-width: #{ $tabletWidth + 1px }) and (max-width: #{ $desktopWidth }) {
        @content;
    }
}

@mixin mobileAndAbove {
    @media (min-width: 0) {
        @content;
    }
}

@mixin mobileAndBelow {
    @media (max-width: #{ $mobileWidth }) {
        @content;
    }
}

@mixin mobileOnly {
    @media (min-width: 0) and (max-width: #{ $mobileWidth }) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin retina {
    @media only screen and (-o-min-device-pixel-ratio: 2 / 1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin tabletAndAbove {
    @media (min-width: #{ $mobileWidth + 1px }) {
        @content;
    }
}

@mixin tabletAndBelow {
    @media (max-width: #{ $tabletWidth - 1px }) {
        @content;
    }
}

@mixin tabletOnly {
    @media (min-width: #{ $mobileWidth + 1px }) and (max-width: #{ $tabletWidth }) {
        @content;
    }
}
