@import 'styles/global.scss';

.container {
    border: 1px solid $editorBorderColor;
    border-radius: $borderRadius;

    & :global(.rdw-option-wrapper) {
        border: 0;
    }

    & :global(.DraftEditor-editorContainer) {
        z-index: unset;
    }

    & :global(.rdw-dropdown-wrapper) {
        border: 0;
    }

    & :global(.public-DraftStyleDefault-block) {
        margin: 0;
    }

    & :global(.rdw-link-modal) {
        height: auto;
        gap: 10px;
        width: 300px;
    }

    & :global(.rdw-link-modal-input) {
        display: block;
        height: 35px;
        margin: 0;
        margin-bottom: 10px;
    }

    & :global(.rdw-embedded-modal) {
        height: auto;
        gap: 10px;
        width: 300px;
    }

    & :global(.public-DraftEditorPlaceholder-inner) {
        @include normalFont;

        padding: 10px 16px;
    }

    & :global(.public-DraftEditor-content) {
        height: 150px;
        line-height: 1.5;
        overflow-y: auto;
        padding: 10px 16px;
    }

    & :global(.rdw-link-modal-target-option) {
        display: none;
    }
}

.colorPicker {
    margin-right: 15px !important;
}

.editor {
    overflow: hidden !important;
}

.embedded {
    margin-right: 15px !important;
}

.inlineGroup {
    margin-right: 15px;
}

.listGroup {
    margin-right: 15px;
}

.toolbar {
    margin: 0;
    border: 0 !important;
    border-bottom: 1px solid $borderColor !important;
    border-radius: $borderRadius $borderRadius 0 0 !important;
}
