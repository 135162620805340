@import 'styles/global.scss';

.activityDetailsPanel {
    @include tabletAndBelow {
        display: none;
    }
}

.activityMetaPanel {
    @include tabletAndBelow {
        display: none;
    }
}

.activityTabs {
    > [data-selected-item='details'] [data-value='details'],
    > [data-selected-item='history'] [data-value='history'],
    > [data-selected-item='meta'] [data-value='meta'] {
        @include activeTab;
    }

    @include desktopAndAbove {
        > [data-selected-item='meta'] [data-value='details'] {
            @include activeTab;
        }
    }
}

.meta {
    @include desktopAndAbove {
        display: none;
    }
}
