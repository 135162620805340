@import 'styles/global.scss';

.content {
    @include normalFont;

    border-radius: $borderRadius;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $tooltipTextColor;
    line-height: 1.4;
    max-width: 600px;
    padding: 10px 12px;
}

.overlay {
    z-index: 100;
}

.policyReference {
    color: black;
    white-space: pre-wrap;
    background: rgb(250, 251, 255);
}
