@import 'styles/global.scss';

.inputLabel {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: -7px;

    &.noLabel {
        display: none;
    }
}

.inputWithLabel {
    position: relative;
    width: 100%;

    &.hasFocus {
        z-index: 1;
    }

    &:hover .inputLabel {
        border-color: $inputBorderHoverColor;
    }

    &.noLabel {
        border-radius: $borderRadius;
        border: 1px solid $inputBorderColor;

        &:hover {
            border-color: $inputBorderHoverColor;
        }
    }
}
