@import 'styles/global.scss';

.description {
    grid-column: 1/3;
}

.fileUpload{
    grid-column: 1/3;
}

.policyInformation {
    grid-column: 1/3;
}

.panelBody {
    display: grid;
    gap: 24px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: min-content min-content min-content;
}

.title {
    grid-column: 1/3;
}

.visibleTo {
    grid-column: 1/3;
}
