@import 'styles/global.scss';

.headerText {
    color: $inactiveTextColor;
    font-weight: 300;

    &:nth-of-type(n + 4) {
        display: none;
    }

    @include tabletAndAbove {
        &:nth-of-type(n + 4) {
            display: table-cell;
        }
    }
}

.titleText {
    font-size: 16px;
}

.overdueTitle{
    color: $overdueItemsTitleColor;
}

.pendingTitle{
    color: $inProgressItemsTitleColor;
}

.closedTitle{
    color: $completedItemsTitleColor;
}

.defaultTitle{
    color: $inactiveTextColor;
}

.dateCustomWrap {
    display: flex;
    gap: 8px;
    flex-direction: column;
}