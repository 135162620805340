@import 'styles/global.scss';

.email {
    color: $selectUserListItemEmailColor;
}

.selectedLink {
    color: $selectUserListItemRemoveColor;
}

.selectUserListItem {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    padding: 12px;
}

.withAvatar {
    grid-template-columns: 48px auto;
    grid-template-rows: 48px;
}
