@import 'styles/global.scss';

.error {
    display: block;
    margin-top: 10px;
}

.formField {
    width: 100%;
}

.label {
    @include normalFont;

    display: block;
    margin-bottom: 10px;
}
