@import 'styles/global.scss';

.closeButton {
    grid-column: 2/3;
    justify-self: end;

    svg {
        fill: $selectUsersDrawerCloseButtonColor;
    }
}

.searchInput {
    grid-column: 1/3;
    margin: 15px 0;
}

.selectUsersDrawer {
    align-content: start;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 28px;
    grid-template-rows: repeat(auto-fill, max-content);
    padding: 35px 25px;
}

.selectUserListItem {
    grid-column: 1/3;
}

.title {
    grid-column: 1/2;
}
