.table {
    font-family: Arial, sans-serif;
    font-size: 14px;
    border-spacing: 0;
    border-collapse: collapse;
    // overflow: auto;
    table-layout: fixed;
    width: calc(100% - 20px);
}

.tableTopPadding {
    margin-top: 25px;
}

.tableFirstCellButtonContainer {
    left: -20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tableColumnButtonContainer {
    position: absolute;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tablePaddingLeft {
    margin-left: 15px;
}

.tableActionsBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin-top: 10px;
}

.tableInput {
    border: none;
    width: 100%;
    background-color: transparent;
    outline: none;
    resize: none;
    overflow: hidden;
    min-height: 20px;
    white-space: normal;
    word-wrap: break-word;
}

.tableTh {
    position: relative;
    border: 1px solid;
    background-color: rgba(#00ab55, 0.2);
}

.tableTd {
    border: 1px solid;
    background-color: white;
}

.tableWrapper {
    padding: 5px;
    background-color: transparent;
}

.tableRemoveButton {
    border-radius: 100%;
    color: white;
    width: 15px;
    height: 15px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}

.tableAcctionButton {
    width: fit-content;
    padding: 5px 10px;
    background-color: rgba(#00ab55, 0.2);
    &:hover {
        background-color: rgba(#00ab55, 0.4);
    }
}
