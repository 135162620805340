@import 'styles/global.scss';

.addQuestions{
    grid-column: 1/4;
}

.checkboxWithLabel {
    grid-column: 1/4;
}

.description {
    grid-column: 1/4;
}

.dueDate {
    grid-column: 1/4;
}

.instructions {
    grid-column: 1/4;
}

.name {
    grid-column: 1/4;
}

.form {
    grid-template-columns: 1fr 1fr 1fr;
}

.recipient {
    grid-column: 1/4;
}

.useDate {
    grid-column: 1/4;
}

.withLabel {
    grid-column: 1/4;
}
