@import '../../styles/global.scss';

.dot {
    animation: spinner 1s infinite ease-in-out;
    border-radius: 100%;
    height: 40px;
    margin: 40px auto;
    width: 40px;
}

.saveSpinner {
    border: 4px solid transparent;
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: savespin 1s linear infinite;
}

.spinner {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

@keyframes spinner {
    0% {
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes saveSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}