@import 'styles/global.scss';

.buttonize,
.statusIcon {
    justify-self: center;
    width: $smallIconSize;
}

.field {
    @include lineclamp(2);
}

.workflowSettingsTableRow {
    grid-template-columns: 1fr 1fr 70px 20px;
}
