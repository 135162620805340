@import 'styles/global.scss';

.fileUpload {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.target {
    align-items: center;
    background-color: $fileUploadBackgroundColor;
    border: 1px dashed $borderColor;
    border-radius: $borderRadius;
    cursor: pointer;
    display: flex;
    height: 240px;
    justify-content: flex-start;
    padding: 0 42px;
    width: 100%;
}

.logo {
    height: 160px;
    object-fit: contain;
    object-position: center;
    margin-right: 40px;
    width: fit-content;
}

.title {
    @include largestFont;

    margin-bottom: 10px;
}

.description {
    @include normalFont;

    color: $inactiveTextColor;
}

.input {
    border: 1px solid $inputBorderColor;
    grid-column: 1/-1;
}