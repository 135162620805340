@import 'styles/global.scss';

.closeButton {
    grid-column: 2/3;
    grid-row: 1/2;
    margin-left: -10px;
    position: relative;
    top: 10px;
    z-index: 1;

    svg {
        fill: $selectUsersDrawerCloseButtonColor;
    }

    @include desktopAndAbove {
        display: none;
    }
}

.navigationDrawer {
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-template-rows: 20px 1fr;
}

.navigationPanel {
    grid-column: 1/3;
    grid-row: 1/3;
}
