@import 'styles/global.scss';

.activityForm {
    gap: $formGap;
    grid-template-columns: 1fr 1fr;
    display: grid;
    height: 100%;
}

.addActivityType {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: auto;
    grid-template-rows: 55px min-content min-content;
    height: 100%;
    width: 100%;
}
