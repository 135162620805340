.addActivityMetaPanel {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.advancedSettings{
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: small !important;
    font-weight: 200 !important;
    border: none !important;
}

.dropdown {
    grid-column: 1/3;
}

.dropdownWithTags {
    grid-column: 1/3;
}

.linkInNewTab {
    color: #3565e1;
    cursor: pointer;
}

.toggleWithLabel {
    display: grid;
    grid-column: 1/3;
    grid-template-columns: min-content min-content max-content;
    margin-bottom: 20px;
}
