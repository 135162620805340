@import 'styles/global.scss';

.buttonize,
.statusIcon {
    justify-self: center;
    width: $smallIconSize;
}

.formSettingsTableRow {
    grid-template-columns: 1fr 2fr 70px 20px;
}
