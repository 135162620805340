@import 'styles/global.scss';

.buttonText {
    font-size: xx-large;
    grid-column: 2/3;
    grid-row: 3/4;
}

.centerChart{
    text-align: center;
}

.chartTitle{
    text-align:center;
}

.icon {
    grid-column: 2/3;
    grid-row: 2/3;
    height: 100px;
    text-align: center;
}

.panelBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
}

.reportsPanel {
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
}

.reportPanelButton {
    border: solid 1px $borderColor;
    border-radius: $borderRadius;
    display: grid;
    grid-template-columns: auto max-content auto;
    grid-template-rows: auto max-content max-content auto;
    height: 100%;
    width: 100%;

    &:hover {
        background-color: $positiveBarListItemBackgroundColor;
        border-color: $positiveBarListItemBorderColor;
        box-shadow: 0px 2px 5px $barListItemActiveShadowColor;
    }

    &:focus{
        background-color: $positiveBarListItemActiveBackgroundColor;
        border-color: $positiveBarListItemActiveBorderColor;
        box-shadow: 0px 2px 5px $barListItemActiveShadowColor;
    }
}

.fullSizeButton {
    grid-column: 1/5;
}