@import 'styles/global.scss';

.content {
    background-color: $contentBackgroundColor;
    height: 100%;
    padding: 32px 16px;
    width: 100%;

    @include tabletAndAbove {
        padding: 32px;
    }
}
