@import 'styles/global.scss';

.closeButton {
    height: 22px;
    position: absolute;
    right: 6px;
    top: 6px;
    width: 22px;
}

.fileUploadThumbnail {
    align-items: center;
    background-color: $fileUploadThumbBackgroundColor;
    border: 1px dashed $transparent;
    border-radius: $borderRadius;
    display: flex;
    height: 72px;
    justify-content: center;
    position: relative;
    width: 72px;
}

.spinner {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    & > div {
        background-color: $fileUploadSpinnerColor;
    }
}

.withError {
    border-color: $red;
}

.withSuccess {
    border-color: $green;
}
