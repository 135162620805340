@import 'styles/global.scss';

.infoBarListItem {
    background-color: $infoBarListItemBackgroundColor;
    border-color: $infoBarListItemBorderColor;

    &:hover {
        background-color: $infoBarListItemActiveBackgroundColor;
        border-color: $infoBarListItemActiveBorderColor;
    }
}
