@import 'styles/global.scss';

.activitiesHeader {
    grid-template-columns: max-content max-content 1fr max-content;
    grid-template-rows: max-content;
    width: 100%;
}

.activitiesViewSelector {
    grid-column: 3/4;
    grid-row: 1/2;
}

.addActivityButton {
    grid-column: -2/-1;
    grid-row: 1/2;
    justify-self: flex-end;
}

.viewAllLink {
    padding-top: 3px;
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: self-end;
}
