@import 'styles/global.scss';

.infoButton {
    @include boldLargeFont;

    background-color: $infoButtonBackgroundColor;
    box-shadow: 0px 8px 16px $infoButtonShadowColor;
    color: $infoButtonTextColor;
    transition-duration: 0.25s;

    &:hover {
        box-shadow: 0px 4px 12px $infoButtonShadowColor;
        transform: translateY(4px);
    }
}
