@import 'styles/global.scss';

.colorDot {
    border-radius: 50%;
    display: inline-block;
    height: 66%;
    width: 66%;
    margin-top:18%;
}

.legendItem {
    display: grid;
    gap: 15px;
    grid-template-columns: 18px auto;
    grid-template-rows: 18px;
}
