@import 'styles/global.scss';

.donutChart {
    grid-column: 1/2;
}

.donutPanel {
    align-items: center;
    display: grid;
    grid-column: 1/-1;
    grid-row: 2/3;
    grid-template-columns: minmax(100px, 245px) 50%;
    justify-content: center;
}

.donutLoading{
    @include centerBoth;
    align-items: center;
    display: grid;
    grid-column: 1/-1;
    grid-row: 2/3;
    backdrop-filter: blur(2px);
    background: $componentOverlayBackgroundColor;
    border-radius: $contentPanelBorderRadius;
}

.legend {
    grid-column: 2/3;
    justify-self: center;
}
