@import 'styles/global.scss';

.infoBarListItem {
    background-color: $infoBarAlternativeListItemBackgroundColor;
    border-color: $infoBarAlternativeListItemBorderColor;

    &:hover {
        background-color: $infoBarAlternativeListItemActiveBackgroundColor;
        border-color: $infoBarAlternativeListItemActiveBorderColor;
    }
}
