@import 'styles/global.scss';

.addActivitiesPanel {
    display: grid;
    grid-template-rows: max-content auto;
}

.noItems{
    text-align: center;
    color: black;
    margin-top: 16px
}

.detailedListItem {
    grid-template-columns: 1.2fr 100px 100px 0.8fr 1fr 1fr 0.5fr 1fr 0.5fr;
}

.detailedListItemMultiTenant{
    grid-template-columns: 1.2fr 100px 100px 0.8fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr;
}

.titleListItem {
    grid-template-columns: 1.2fr 100px 100px 0.8fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr;
}

.titleListItemMultiTenant{
    grid-template-columns: 1.2fr 100px 100px 0.8fr 1fr 1fr 0.5fr 0.5fr 0.5fr 1fr 0.5fr;
}

.title {
    position: absolute;
}

.barListItem {
    align-items: center;
    background-color: $barListItemBackgroundColor;
    border-left-style: solid;
    border-left-width: 4px;
    border-color: $barListItemBorderColor;
    display: grid;
    gap: 15px;
    grid-template-rows: 20px;
    padding: 15px 16px 15px 21px;

    &:hover {
        background-color: $barListItemActiveBackgroundColor;
        border-color: $barListItemActiveBorderColor;
        box-shadow: 0px 2px 5px $barListItemActiveShadowColor;
    }
}

.listItemPending {
    background-color: $infoBarListItemBackgroundColor;
    border-color: $infoBarListItemBorderColor;

    &:hover {
        background-color: $infoBarListItemActiveBackgroundColor;
        border-color: $infoBarListItemActiveBorderColor;
    }
}

.listItemOverdue {
    background-color: $negativeBarListItemBackgroundColor;
    border-color: $negativeBarListItemBorderColor;

    &:hover {
        background-color: $negativeBarListItemActiveBackgroundColor;
        border-color: $negativeBarListItemActiveBorderColor;
    }
}

.listItemUpcoming {
    background-color: $infoBarAlternativeListItemBackgroundColor;
    border-color: $infoBarAlternativeListItemBorderColor;

    &:hover {
        background-color: $infoBarAlternativeListItemActiveBackgroundColor;
        border-color: $infoBarAlternativeListItemActiveBorderColor;
    }
}

.listItemCompleted {
    background-color: $positiveBarListItemBackgroundColor;
    border-color: $positiveBarListItemBorderColor;

    &:hover {
        background-color: $positiveBarListItemActiveBackgroundColor;
        border-color: $positiveBarListItemActiveBorderColor;
    }
}


.loadMore {
    @include normalFont;
    color: $asideLinkTextColor;
    white-space: nowrap;
    cursor: pointer;
}

.actions {
    display: flex;
    height: 22px;
}

.icon{
    width: 20px;
    height: 20px;
}