@import 'styles/global.scss';

.overviewPanel {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.entriesCount {
    font-size: 10em;
    font-weight: 400;
    text-align: center;
}

@include desktopAndAbove {

    .panelBody {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .overviewHeader{
        grid-row: 2/3;
    }

    .entriesHeader{
        grid-column: 1/3;
        grid-row: 3/4;
    }

    .categoriesHeader{
        grid-column: 3/5;
        grid-row: 3/4;
    }

    .riskHeader{
        grid-column: 5/7;
        grid-row: 3/4;
    }

    .categoryDropdown{
        grid-column: 1/3;
    }

    .startDate{
        grid-column: 3/5;
    }

    .endDate{
        grid-column: 5/7;
    }

    .typeDonutChart {
        grid-column: 3/4;
        grid-row: 4/5;
    }

    .riskDonutChart {
        grid-column: 5/6;
        grid-row: 4/5;
    }

    .entriesCount{
        grid-column: 1/3;
        grid-row: 4/5;
    }

    .typeLegend {
        align-self: center;
        grid-column: 4/5;
        grid-row: 4/5;
    }

    .riskLegend {
        align-self: center;
        grid-column: 6/7;
        grid-row: 4/5;
    }
}