@import 'styles/global.scss';

.titleListItem {
    background-color: transparent;
    border: none;
    cursor: auto;
    padding-bottom: 0;
    padding-top: 0;

    &:hover {
        box-shadow: none;
    }
}
