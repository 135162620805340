@import 'styles/global.scss';

.addFormMetaPanel {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.dropdown {
    grid-column: 1/3;
}

.dropdownWithTags {
    grid-column: 1/3;
}

.toggleWithLabel {
    grid-column: 1/3;
}

.radioButtonContainer{
    border-radius: $borderRadius;
    border: 1px solid $inputBorderColor;
    padding: 16px;
}

.radioButtonLabel{
    margin-bottom: 15px;
}

.dropDownContainer{
    border-radius: $borderRadius;
    border: 1px solid $inputBorderColor;
    padding: 16px;
}

.dropDownOption{
    margin-left: 10%;
    width: 90%
}

.icon{
    cursor: grab !important;
}

.elementContainer{
    display: grid;
    grid-template-columns: 25px auto;
    cursor:default;
}

.draggable {
    top: auto !important;
    left: auto !important;
}

.deleteContainer{
    display: grid;
    grid-template-columns: auto 25px;
}