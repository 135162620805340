@import 'styles/global.scss';

.attachments {
    grid-column: 1/4;
}

.activityReferenceContainer {
    display: grid;
    grid-template-columns:  max-content max-content auto;
    margin-left: auto;
}

.activityReferenceWithLink {
    color: #3565e1;
    cursor: pointer;
}

.activityReferenceWithoutLink {
    cursor: default;
}

.activityReferenceRiskPill {
    grid-column: 2/3;
    grid-row: 2/3;
}

.addQuestions{
    grid-column: 1/4;
}

.caseDetailsPanel {
    display: grid;
    grid-template-rows: max-content;
    height: 100%;
}

.comments {
    grid-column: 1/4;
}

.creationReason {
    grid-column: 1/4;
}

.dropdowns {
    display: grid;
    grid-column: 1/4;
    grid-template-columns: 1fr 1fr 1fr;
}

.fileUpload {
    grid-column: 1/4;
}

.forms {
    display: grid;
    grid-column: 1/4;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $formGap;
    width: 100%;
}

.multipleWithLabel{
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    gap: 5px;
    align-items: flex-start;
}

.panelBody {
    gap: 28px;
    grid-template-columns: 1fr 1fr 1fr;
}

.test {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: auto;
}

.panelBody > :nth-child(2) {
    grid-template-columns: 1fr 16px 5px 16px 10px;

    > :first-child {
        grid-column: 1/6;
        grid-row: 1/4;
    }
}

.policyReferenceRiskPill {
    grid-column: 2/3;
}

.policyReferenceWithLink {
    color: #3565e1;
    cursor: pointer;
}

.policyReferenceWithoutLink {
    cursor: default;
}

.riskLevelRadioButtons {
    color: $inactiveTextColor;
    grid-column: 1/4;
}

.sectionWithBorder {
    display: grid;
    grid-column: 1/4;
    grid-template-columns: 1fr 1fr 1fr;
}

.wrapperButtonize {
    grid-column: 4;
    grid-row: 2;
}

