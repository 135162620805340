@import 'styles/global.scss';

.contentBox {
    grid-column: 1/5;
}

.panelBody {
    display: grid;
    gap: 14px;
    grid-template-columns: repeat(4, 1fr);
}

.title {
    grid-column: 1/5;
}
