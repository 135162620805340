@import 'styles/global.scss';

.notificationIcon {
    position: relative;

    &::after {
        @include smallerFont;

        align-items: center;
        background-color: red;
        border-radius: 50%;
        color: white;
        content: attr(data-count);
        display: flex;
        height: 50%;
        justify-content: center;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 50%;
    }
}