@import 'styles/global.scss';

.reqestContentBox {
    border-color: $infoContentBoxBorderColor;
    color: $infoContentBoxTextColor;
    display: grid;
    grid-template-columns: max-content 1fr 1fr;
}

.responseContentBox {
    border-color: $purpleContentBoxBorderColor;
    color: $purpleContentBoxTextColor;
    display: grid;
    grid-template-columns: max-content 1fr 1fr;
}

.title{
    grid-column: 1/2;
}

.date{
    grid-column: 3/4;
    text-align: right;
}

.text{
    grid-column: 1/3;
    grid-row: 3/4;
}

.user{
    grid-column: 3/4;
    grid-row: 2/3;
    text-align: right;
}