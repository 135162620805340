@import 'styles/global.scss';

.text {
    color: inherit;
}

.versionButton {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid;
    border-radius: $borderRadius;
    text-transform: capitalize;

    &.selected {
        color: $white;
    }
}
