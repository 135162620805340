@import 'styles/global.scss';

.tableRow {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-template-rows: 20px;
    gap: 20px;
    padding: 20px;
    width: 100%;
}
