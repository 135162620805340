@import 'styles/global.scss';

.addActivityForm {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.activityForm {
    gap: $formGap;
    grid-template-columns: 1fr 1fr;
    display: grid;
    height: 100%;
}

.dropdownWide {
    grid-column: 1/3;
}

.editor {
    grid-column: 1/3;
}

// .expandDownArrowIcon {
//     width: 10px;
//     height: 10px;
// }

// .expandForm {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border: 1px solid $inputBorderColor;
//     border-radius: 8px;
//     box-sizing: border-box;
//     cursor: pointer;
//     grid-column: 1/3;
//     padding: 20px;
//     width: 100%;
//     height: auto;

//     @include tabletAndAbove {
//         grid-template-columns: 1fr 1fr 1fr;
//     }
// }

.isExpandedForm {
    transform: scaleY(-1);
}

.textInput {
    grid-column: 1/3;
}

.tableContainer {
    grid-column: 1/3;
}

.activityTableHeader {
    background-color: $caseTableHeaderBackgroundColor;
    grid-template-columns: 1fr 1fr 1fr;

    @include tabletAndAbove {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.headerText {
    &:nth-of-type(n + 4) {
        display: none;
    }

    @include tabletAndAbove {
        &:nth-of-type(n + 4) {
            display: table-cell;
        }
    }
}

.formTableRow {
    padding-top: 25px;
}