@import 'styles/global.scss';

.case {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: auto;
    grid-template-rows: 55px auto;
    height: 100%;
    width: 100%;
}
