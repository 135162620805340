@import 'styles/global.scss';

.buttonize {
    position: absolute;
    right: 16px;
    top: 16px;
    width: $smallIconSize !important;

    @include tabletAndAbove {
        display: none;
    }
}

.contentPanel {
    max-width: 100%;
    width: 100%;

    @include mobileOnly {
        border-radius: 0;
        min-height: 100vh;
    }

    @include tabletAndAbove {
        max-height: 100%;
        overflow-y: auto;
        width: max-content;
    }
}

.hideWhenModalIsOpen {
    @include mobileOnly {
        display: none;
    }
}

.modal {
    align-items: center;
    display: flex;
    height: unset;
    min-height: 100%;
    justify-content: center;

    @include mobileOnly {
        position: absolute;
    }

    @include tabletAndAbove {
        height: 100%;
    }

    @include desktopAndAbove {
        padding: 10px;
        z-index: 2;
    }
}
