@import 'styles/global.scss';

.employeesPanel {
    grid-row: 2/3;
}

.humanResources {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 70px auto;
    height: 100%;
    width: 100%;
}
