@import 'styles/global.scss';

.active {
    background-color: $activeVersionTagColor;
}

.archived {
    background-color: $archivedVersionTagColor;
}

.draft {
    background-color: $draftVersionTagColor;
}

.versionTag {
    @include smallerFont;

    border-radius: 6px;
    color: $versionTagTextColor;
    font-weight: 500;
    padding: 4px 6px;
    text-transform: capitalize;
}
