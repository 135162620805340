@import 'styles/global.scss';

.bubble {
    @include centerBoth;
    @include largestFont;

    border: 1px solid black;
    border-radius: 100%;
    display: flex;
    height: 100%;
    width: 100%;
}
