@import 'styles/global.scss';

.contentBox {
    display: grid;
    gap: 12px;
    grid-template-rows: min-content 1fr;
    width: 100%;
}

.htmlContent > ul {
    list-style: circle;
    list-style-position: inside;
    list-style-type: disc;
}

.topPadded {
    margin-top: 10px;
}