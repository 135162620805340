@import 'styles/global.scss';

.comments {
    display: grid;
    gap: 18px;
}

.verticalScroll {
    height: 100%;
    overflow-y: auto;
}

.verticalScrollContainer {
    border: none;
    border-radius: $borderRadius;
    max-height: 500px;
    overflow: auto;
}
