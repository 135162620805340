@import 'styles/global.scss';

.body {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 58px auto;
    padding: 0 35px 16px;
}

.groupMemberSection {
    align-items: center;
    padding: 24px 0;
    // background-color: $whiteSmoke;
    // padding: 10px 35px;
}

.list {
    display: grid;
    grid-column: 1/3;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // grid-template-rows: repeat(auto-fit, 80px);

}

.members {
    display: grid;
    gap: 20px;
    grid-column: 1/2;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fit, 80px);
}

.panelBody {
    gap: 16px;
    padding: 0;
}
