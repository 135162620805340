@import 'styles/global.scss';

.contentBox {
    grid-column: 1/5;
}

.dateOfOccurrence {
    grid-column: 4/5;
}

.dropdowns {
    display: grid;
    grid-column: 1/5;
    grid-template-columns: 1fr 1fr 1fr;
}

.panelBody {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content min-content minmax(100px, max-content) min-content;
}

.title {
    grid-column: 1/5;
}
