@import 'styles/global.scss';

.buttons {
    display: grid;
    gap: 14px;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-rows: 40px;
    justify-content: center;
    width: 100%;
}
