@import 'styles/global.scss';

.approvedVersionContainer {
    display: flex;
}

.approvedVersion {
    color: $lightBlue;
		max-width: 100px;
		@include tabletOnly {
			max-width: 100px;
		}
		@include tabletAndBelow {
			max-width: 80px;
		}
}

.detailsButton {
    grid-column: 4/5;

    @include tabletAndAbove {
        grid-column: 8/9;
    }
}

.entities {
    display: none;

    @include tabletAndAbove {
        display: inline-block;
    }
}

.importantLink {
    display: none;

    @include tabletAndAbove {
        display: inline-block;
    }
}

.titleLink {
}

.riskPill {
    display: none;
    grid-column: 7/8;

    @include tabletAndAbove {
        display: inline-block;
    }
}

.hideBackgroundUntilHover {
    background-color: transparent;
    border-color: transparent;
}

.link{
    text-decoration: none;
}