@import 'styles/global.scss';

.iconWithTitle {
    align-items: center;
    display: flex;
    gap: 12px;
    width: 100%;
}

.title {
    white-space: nowrap;
}
