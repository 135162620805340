@import 'styles/global.scss';

.editActivity {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: auto;
    grid-template-rows: 55px auto max-content;
    height: 100%;
    width: 100%;
}
