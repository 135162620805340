@import 'styles/global.scss';

.badge {
    @include normalFont;

    background-color: $pillBackgroundColor;
    padding: 4px 12px;
    border-radius: 16px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    white-space: nowrap;
}

.groupBadge {
    background-color: $userInputGroupPillBackgroundColor;
    color: $userInputPillTextColor;
}

.userBadge {
    background-color: $userInputUserPillBackgroundColor;
    color: $userInputPillTextColor;
}

.badgesContainer {
    display: flex;
    gap: 5px;
    overflow: hidden;
}

.badge {
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
}
