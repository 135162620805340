@import 'styles/global.scss';

.activeSortArrow {
    opacity: 1 !important;
}

.sortableHeader {
    align-items: center;
    display: flex;
    gap: 10px;

    &:hover .sortArrow {
        opacity: 0.5;
    }

    &:hover .activeSortArrow {
        opacity: 1;
    }
}

.sortArrow {
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 0;
    transition: opacity;
    width: 20px;
}
