@import 'styles/global.scss';

.panelBody {
    overflow-y: visible;
}

.errorMessage {
    color: $errorColor;
    font-size: 14px;    
}

.personalInformationPanel {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.fullWidth {
    grid-column: 1/3;
}

.toggleWithLabel {
    margin-top: -10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        color: black;
        font-size: 14px;
    }
}