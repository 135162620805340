// IMPORTANT REMINDER: Always keep this alphabetized!

// DO NOT USE THESE VARIABLES DIRECTLY IN APP CODE
// Colors
$activeGreen: #00ab562a;
$babyBlue: #fafbff;
$black: #000000;
$blue: #3565e1;
$lightBlue: #0062ff;
$darkBabyBlue: #ebf0fc;
$darkGray: #979797;
$darkPink: #f9d5d6;
$darkSmoke: #637381;
$darkTransparentGreen: rgba(0, 171, 85, 0.3);
$darkTransparentOrange: rgba(242, 153, 74, 0.3);
$darkTransparentPurple: #826AF91a;
$darkTransparentRed: rgba(229, 46, 48, 0.3);
$ghost: #c4cdd5;
$gray: #919eab;
$green: #00ab55;
$lightGray: #f9fafb;
$lightGreen: #c5e2d3;
$lightMediumGray: #d0d5da;
$lightExtraMediumGray: #e4e8ed;
$lightOrange: #eabf99;
$orange: #f2994a;
$purple: #826AF9;
$red: #eb5b5e;
$silver: #e6e8f0;
$transparent: transparent;
$transparentBlack: #161c24aa;
$transparentBlue: #3565e10a;
$transparentDarkGreen: #00ab553d;
$transparentGreen: #00ab550a;
$transparentGray: #5b5b5b59;
$transparentOrange: #f2994a0f;
$transparentPurple: #826AF90a;
$transparentRed: #e52e300f;
$transparentSilver: #919eab1f;
$transparentSmoke: #919eab3d;
$transparentSmokeyGray: #c8c8c8e6;
$transparentText: rgba(255,255,255,0.9);
$transparentWhite: #ffffff80;
$white: #ffffff;
$whiteSmoke: #f4f6f8;

// DO NOT USE THESE VARIABLES DIRECTLY IN APP CODE
// Semantic Colors
$activeBackgroundColor: $gray;
$activeBorderColor: $black;
$activeColor: $black;
$alertTextColor: $orange;
$backgroundColor: $babyBlue;
$borderColor: $silver;
$errorColor: $red;
$focusedBackgroundColor: $transparentSilver;
$focusedInputBorderColor: $green;
$focusedInputLabelColor: $green;
$foregroundColor: $white;
$formElementBackgroundColor: $white;
$formElementBorderColor: $borderColor;
$formElementForegroundColor: $black;
$formElementHoverBorderColor: $darkGray;
$highlightTextColor: $green;
$hoverBackgroundColor: $lightGray;
$inactiveBackgroundColor: $silver;
$inactiveBorderColor: $darkGray;
$inactiveColor: $darkGray;
$inactiveStrokeColor: $darkGray;
$inactiveTextColor: $darkGray;
$infoActiveBackgroundColor: $darkBabyBlue;
$infoBackgroundColor: $transparentBlue;
$infoBorderColor: $blue;
$infoColor: $blue;
$infoAlternativeActiveBackgroundColor: $darkTransparentPurple;
$infoAlternativeBackgroundColor: $transparentPurple;
$infoAlternativeBorderColor: $purple;
$infoAlternativeColor: $purple;
$infoShadowColor: $transparentBlue;
$infoTextColor: $blue;
$inverseTextColor: $white;
$linkColor: $blue;
$negativeActiveBackgroundColor: $darkPink;
$negativeBackgroundColor: $transparentRed;
$negativeBorderColor: $red;
$negativeColor: $red;
$negativeStrokeColor: $red;
$negativeTextColor: $red;
$neutralActiveBackgroundColor: $transparent;
$neutralBackgroundColor: $transparent;
$neutralBorderColor: $transparent;
$neutralTextColor: $black;
$positiveActiveBackgroundColor: $activeGreen;
$positiveBackgroundColor: $transparentGreen;
$positiveBorderColor: $green;
$positiveColor: $green;
$positiveTextColor: $green;
$shadowColor: $transparentSilver;
$textColor: $black;

// USE --ONLY-- THESE VARIABLES IN APP CODE
// Component Colors
$actionMenuItemHoverBackgroundColor: $hoverBackgroundColor;
$activeTextColor: $activeColor;
$activeVersionButtonBorderColor: $positiveBorderColor;
$activeVersionButtonInverseBackgroundColor: $positiveColor;
$activeVersionButtonTextColor: $positiveTextColor;
$activeVersionTagColor: $positiveColor;
$activityPolicyTextColor: $lightBlue;
$activityCommentsBorderColor: $borderColor;
$activityUserCompletedColor: $green;
$activityUserCompletedLateColor: $orange;
$activityUserPastDueColor: $red;
$activityUserStatusColor: $inactiveTextColor;
$addNewBackgroundColor: $transparent;
$addNewTextColor: $blue;
$archivedVersionButtonBorderColor: $negativeBorderColor;
$archivedVersionButtonInverseBackgroundColor: $negativeColor;
$archivedVersionButtonTextColor: $negativeTextColor;
$archivedVersionTagColor: $negativeColor;
$asideLinkTextColor: $positiveTextColor;
$avatarBackgroundColor: $ghost;
$avatarAltTextColor: $darkSmoke;
$barListItemActiveBackgroundColor: $neutralActiveBackgroundColor;
$barListItemActiveBorderColor: $neutralBorderColor;
$barListItemActiveShadowColor: $shadowColor;
$barListItemBackgroundColor: $neutralBackgroundColor;
$barListItemBorderColor: $neutralBorderColor;
$bodyTextColor: $textColor;
$caseTableHeaderBackgroundColor: $whiteSmoke;
$checkboxBorderColor: $gray;
$checkboxColor: $green;
$checkboxCheckColor: $white;
$closeButtonBackgroundColor: $transparent;
$closeIconStrokeColor: $black;
$commentDateTextColor: $inactiveTextColor;
$completedItemsTitleColor: $positiveTextColor;
$componentOverlayBackgroundColor: $backgroundColor;
$contentBackgroundColor: $backgroundColor;
$contentBoxBorderColor: $lightExtraMediumGray;
$contentPanelBackgroundColor: $foregroundColor;
$contentShadowColor: $shadowColor;
$dangerBulletBackgroundColor: $red;
$deleteButtonBorderColor: $negativeBorderColor;
$deleteButtonTextColor: $negativeTextColor;
$disableCheckedRadioButton: $silver;
$doneCountPanelBackgroundColor: $positiveActiveBackgroundColor;
$donutPiePaletteGreen: $green;
$dotActiveBackgroundColor: $green;
$dotBackgroundColor: $inactiveBackgroundColor;
$draftVersionButtonBorderColor: $infoBorderColor;
$draftVersionButtonInverseBackgroundColor: $infoColor;
$draftVersionButtonTextColor: $infoTextColor;
$draftVersionTagColor: $infoColor;
$drawerBackgroundColor: $backgroundColor;
$drawerShadowColor: $shadowColor;
$editButtonBackgroundColor: $green;
$editorBorderColor: $borderColor;
$errorBackgroundColor: $transparentRed;
$errorTextColor: $errorColor;
$fileUploadBackgroundColor: $whiteSmoke;
$fileUploadSpinnerColor: $green;
$fileUploadThumbBackgroundColor: $whiteSmoke;
$groupTagBackgroundColor: $orange;
$hamburgerLineColor: $black;
$headerBackgroundColor: $foregroundColor;
$headerTextColor: $darkSmoke;
$historyEntriesBackgroundColor: $whiteSmoke;
$hollowButtonBackgroundColor: $transparent;
$hollowButtonBorderColor: $borderColor;
$hollowButtonShadowColor: $transparent;
$hollowButtonTextColor: $textColor;
$inProgressCountPanelBackgroundColor: $infoActiveBackgroundColor;
$inProgressItemsTitleColor: $infoTextColor;
$inactiveTextColor: $inactiveColor;
$infoBarListItemActiveBackgroundColor: $infoActiveBackgroundColor;
$infoBarListItemActiveBorderColor: $infoBorderColor;
$infoBarListItemBackgroundColor: $infoBackgroundColor;
$infoBarListItemBorderColor: $infoBorderColor;
$infoBarAlternativeListItemActiveBackgroundColor: $infoAlternativeActiveBackgroundColor;
$infoBarAlternativeListItemActiveBorderColor: $infoAlternativeBorderColor;
$infoBarAlternativeListItemBackgroundColor: $infoAlternativeBackgroundColor;
$infoBarAlternativeListItemBorderColor: $infoAlternativeBorderColor;
$infoButtonBackgroundColor: $infoColor;
$infoButtonShadowColor: $infoShadowColor;
$infoButtonTextColor: $inverseTextColor;
$infoContentBoxBorderColor: $infoBorderColor;
$infoContentBoxTextColor: $infoTextColor;
$purpleContentBoxBorderColor: $purple;
$purpleContentBoxTextColor: $purple;
$infoHollowButtonBackgroundColor: $infoBackgroundColor;
$infoHollowButtonBorderColor: $infoBorderColor;
$infoHollowButtonTextColor: $infoTextColor;
$initialsTextColor: $darkSmoke;
$inputBackgroundColor: $foregroundColor;
$inputBorderColor: $formElementBorderColor;
$inputBorderHoverColor: $formElementHoverBorderColor;
$inputLabelLegendTextColor: $inactiveTextColor;
$inputPlaceholderColor: $inactiveTextColor;
$inputTextColor: $textColor;
$lowRiskPillBackgroundColor: $positiveBackgroundColor;
$lowRiskPillTextColor: $positiveTextColor;
$mediumRiskPillBackgroundColor: $infoBackgroundColor;
$mediumRiskPillTextColor: $infoTextColor;
$menuHoverBackgroundColor: $focusedBackgroundColor;
$menuItemHoverBackgroundColor: $hoverBackgroundColor;
$menuListItemActiveBackgroundColor: $positiveActiveBackgroundColor;
$menuListItemBackgroundColor: $backgroundColor;
$menuListItemActiveBorderColor: $positiveBorderColor;
$menuListItemBorderColor: $backgroundColor;
$menuListItemHoverBackgroundColor: $positiveBackgroundColor;
$navigationMenuBackgroundColor: $backgroundColor;
$navigationMenuBorderColor: $borderColor;
$negativeBarListItemActiveBackgroundColor: $negativeActiveBackgroundColor;
$negativeBarListItemActiveBorderColor: $negativeBorderColor;
$negativeBarListItemBackgroundColor: $negativeBackgroundColor;
$negativeBarListItemBorderColor: $negativeBorderColor;
$negativeHollowButtonBackgroundColor: $negativeBackgroundColor;
$negativeHollowButtonBorderColor: $negativeBorderColor;
$negativeHollowButtonTextColor: $negativeTextColor;
$neutralBarListItemActiveBackgroundColor: $neutralActiveBackgroundColor;
$neutralBarListItemActiveBorderColor: $neutralBorderColor;
$neutralBarListItemBackgroundColor: $neutralBackgroundColor;
$neutralBarListItemBorderColor: $neutralBorderColor;
$neutralRiskPillBackgroundColor: $neutralBackgroundColor;
$neutralRiskPillTextColor: $neutralTextColor;
$overdueCountPanelBackgroundColor: $negativeActiveBackgroundColor;
$overdueItemsTitleColor: $negativeTextColor;
$overlayBackgroundColor: $transparentGray;
$pageTitleColor: $textColor;
$panelOverlayBackgroundColor: $transparentSmokeyGray;
$panelTitleColor: $textColor;
$pillBackgroundColor: $silver;
$positiveBarListItemActiveBackgroundColor: $positiveActiveBackgroundColor;
$positiveBarListItemActiveBorderColor: $positiveBorderColor;
$positiveBarListItemBackgroundColor: $positiveBackgroundColor;
$positiveBarListItemBorderColor: $positiveBorderColor;
$positiveBulletBackgroundColor: $green;
$positiveHollowButtonBackgroundColor: $positiveBackgroundColor;
$positiveHollowButtonBorderColor: $positiveBorderColor;
$positiveHollowButtonTextColor: $positiveTextColor;
$publishPolicyVersionButton: $lightBlue;
$radioButtonBulletColor: $formElementForegroundColor;
$radioButtonHoverColor: $transparentSilver;
$riskCriticalColor: $red;
$riskHighColor: $orange;
$riskLowColor: $green;
$riskMediumColor: $blue;
$sectionWithBorderBorderColor: $borderColor;
$selectUsersDrawerCloseButtonColor: $gray;
$selectUserListItemEmailColor: $inactiveTextColor;
$selectUserListItemRemoveColor: $red;
$slideToggleBackgroundColor: $lightExtraMediumGray;
$slideToggleBackgroundGreen: $lightGreen;
$slideToggleKnobColor: $green;
$slideToggleKnobShadowColor: $shadowColor;
$submitButtonBackgroundColor: $green;
$submitButtonShadowColor: $transparentDarkGreen;
$submitButtonTextColor: $inverseTextColor;
$subTitleTextColor: $inactiveTextColor;
$tabActiveBorderColor: $green;
$tabActiveTextColor: $textColor;
$tabBackgroundColor: $transparent;
$tabBorderColor: $transparent;
$tabHoverBackgroundColor: $whiteSmoke;
$tabTextColor: $inactiveTextColor;
$tableHeaderBackgroundColor: $whiteSmoke;
$tableInfoAlternativeTitleTextColor: $purple;
$tableInfoTitleTextColor: $infoTextColor;
$tableNegativeTitleTextColor: $negativeTextColor;
$tablePositiveTitleTextColor: $positiveTextColor;
$tagTextColor: $white;
$tooltipTextColor: $inactiveTextColor;
$upcomingCountPanelBackgroundColor: $infoAlternativeActiveBackgroundColor;
$upcomingItemsTitleColor: $purple;
$userInputGroupPillBackgroundColor: $orange;
$userInputPillTextColor: $white;
$userInputUserPillBackgroundColor: $lightBlue;
$userTagBackgroundColor: $blue;
$versionTagTextColor: $white;
$verticalDividerBackgroundColor: $transparentSmoke;
$warningBulletBackgroundColor: $orange;
$highRiskPillBackgroundColor: $transparentOrange;
$highRiskPillTextColor: $orange;
$criticalRiskPillBackgroundColor: $transparentRed;
$criticalRiskPillTextColor: $red;
$dangerNoticeBackgroundColor: $transparentRed;
$dangerNoticeTextColor: $red;
$moveIconStrokeColor: $gray;