@import 'styles/global.scss';

.complianceLogTableRow {
    gap: 30px;
    grid-template-columns: 200px 200px 1fr 70px 40px;
}

.complianceLogTableRowMultiTenant {
    gap: 30px;
    grid-template-columns: 200px 200px 200px 1fr 70px 40px;
}

.description {
    @include lineclamp(2);

    white-space: initial;
}

.detailsButton {
    height: 18px;
    justify-self: end;
    width: 25px;
}

.link {
    color: $textColor;
}

.titleLink {
    font-weight: 600;
}