@import 'styles/global.scss';

.adminMasterEmailAddress {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.input {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
}
