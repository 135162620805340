@import 'styles/global.scss';

.caseTableHeader {
    grid-template-columns: 0.7fr 0.7fr 0.5fr 0.4fr 40px;

    @include tabletAndAbove {
        grid-template-columns: 0.7fr 1fr 100px 120px 1fr 100px 80px 1fr 0.4fr 40px 1fr;
    }
}

.caseTableHeaderMultiTenant{
    grid-template-columns: 0.7fr 0.7fr 0.5fr 0.4fr 40px;

    @include tabletAndAbove {
        grid-template-columns: 0.7fr 1fr 100px 120px 1fr 100px 80px 1fr 0.4fr 0.4fr 40px 1fr;
    }
}

.caseTableHeaderDashboard {
    grid-template-columns: 0.8fr 1fr 0.5fr 1fr;
}

.headerText {
    color: $inactiveTextColor;
    font-weight: 300;

    &:nth-of-type(n + 4) {
        display: none;
    }

    @include tabletAndAbove {
        &:nth-of-type(n + 4) {
            display: table-cell;
        }
    }
}

.titleText {
    font-size: 16px;
}

.overdueTitle{
    color: $overdueItemsTitleColor;
}

.pendingTitle{
    color: $inProgressItemsTitleColor;
}

.closedTitle{
    color: $completedItemsTitleColor;
}

.defaultTitle{
    color: $inactiveTextColor;
}