@import 'styles/global.scss';

.iconWithText {
    align-items: center;
    cursor: pointer;
    display: grid;
    gap: 10px;
    grid-template-columns: 15px auto;
    opacity: 100%;

    &:hover {
        opacity: 100%;
    }

    &.isActive {
        opacity: 100%;
    }
}
