@import 'styles/global.scss';

.avatar {
    grid-column: 1/2;
    grid-row: 2/3;
}

.commentInput {
    display: grid;
    grid-template-columns: 30px 12px 1fr;
    grid-template-rows: 12px 30px 12px;
}

.drawer {
    border: 1px solid $inputBorderColor;
    border-radius: 8px;
    cursor: pointer;
    grid-column: 3/4;
    grid-row: 4/5;
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.input {
    border: 1px solid $inputBorderColor;
    grid-column: 3/4;
    grid-row: 1/4;
}

.avatarWithTitle{
    padding:10px;
}

.button{
    background-color: transparent;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid $inputBorderColor;
    cursor: pointer;
}