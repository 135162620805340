@import 'styles/global.scss';

.documentManagementTableRow {
    gap: 30px;
    grid-template-columns: 250px 120px 1fr 1fr 0.7fr 1fr;
}

.documentManagementTableRowMulti {
    gap: 30px;
    grid-template-columns: 250px 120px 1fr 1fr 1fr 0.7fr 1fr;
}

.version {
    justify-self: center;
}

.detailsButton {
    height: 18px;
    justify-self: end;
    width: 25px;
}

.link {
    color: $linkColor;
    cursor: pointer;
}

.statusIcon {
    justify-self: center;
    width: $smallIconSize;
}
