@import 'styles/global.scss';

.panelBody {
    height: 100%;
}

.formsManagementPanel {
    display: grid;
    height: 100%;
}

.table {
    height: 100%;
}
