@import 'styles/global.scss';

.closeButton {
    align-items: center;
    background-color: $closeButtonBackgroundColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 0;
}
