@import 'styles/global.scss';

.documentManagementRepositoryTableHeader {
    gap: 30px;
    grid-template-columns: 250px 120px 1fr 1fr 0.7fr 1fr;
}

.documentManagementRepositoryTableHeaderMulti {
    gap: 30px;
    grid-template-columns: 250px 120px 1fr 1fr 1fr 0.7fr 1fr;
}