@import 'styles/global.scss';

.cancelSaveButtons {
    justify-content: center;
}

.fullWidth{
    grid-column: 1/5;
}

.modal{
    overflow-y: visible;
}

.modalGrid{
    display: grid;
    grid-template-columns: 1fr 1fr max-content max-content;
    min-width: 50vw;
    overflow-y: visible;
}

.panelTitle {
    justify-self: center;
    grid-column: 1/5;
}

.statusIcon {
    text-align: center;
    width: 20px;
}

.wideDrop {
    grid-column: 1/3;
}