@import 'styles/global.scss';

.withSectionTitle {
    align-items: center;
    display: grid;
    gap: 14px;
    grid-template-columns: auto max-content;
    grid-template-rows: 36px;
    width: 100%;
}
