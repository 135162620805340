@import 'styles/global.scss';

.editGroup {
    display: grid;
    gap: $layoutGap;
    grid-template-columns: auto;
    grid-template-rows: 55px min-content min-content;
    height: 100%;
    width: 100%;
}
