@import 'styles/global.scss';



.content {
    grid-row: 3/4;
    grid-column: 1/2;
}

.unauthenticatedContent {
    background-color: $contentBackgroundColor;
    display: grid;
    gap: 14px;
    grid-template-columns: 1fr;
    grid-template-rows: 75px 50px max-content 1fr;
    height: 100%;
    width: 100%;
}

@include desktopAndAbove {

    .content {
        grid-row: 3/4;
        grid-column: 2/3;
    }
    
    .unauthenticatedContent {
        background-color: $contentBackgroundColor;
        display: grid;
        gap: 14px;
        grid-template-columns: 1fr 468px 1fr;
        grid-template-rows: 75px .4fr max-content 1fr;
        height: 100%;
        width: 100%;
    }
}