@import 'styles/global.scss';

.caseTableRow {
    grid-template-columns: 0.7fr 0.7fr 0.5fr 0.4fr;
    grid-template-rows: 20px;

    @include tabletAndAbove {
        grid-template-columns: 0.7fr 1fr 100px 120px 1fr 100px 80px 1fr 0.4fr 40px 1fr;
    }
}

.caseTableRowMultiTenant{
    grid-template-columns: 0.7fr 0.7fr 0.5fr 0.4fr 40px;
    grid-template-rows: 20px;

    @include tabletAndAbove {
        grid-template-columns: 0.7fr 1fr 100px 120px 1fr 100px 80px 1fr 0.4fr 0.4fr 40px 1fr;
    }
}

.caseTableRowDashboard {
    grid-template-columns: 0.8fr 1fr 0.5fr 1fr;
    grid-template-rows: 20px;
}

.detailsButton {
    grid-column: 5/6;

    @include tabletAndAbove {
        grid-column: 10/11;
    }
}

.detailsButtonDashboard {
    grid-column: 5/6;
}

.entities {
    display: none;

    @include tabletAndAbove {
        display: inline-block;
    }
}

.importantLink {
    display: none;

    @include tabletAndAbove {
        display: inline-block;
        color: $bodyTextColor;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
    }
}

.originatedWithLink {
    color: #3565e1;
    cursor: pointer;
}

.originatedWithoutLink {
    cursor: default;
}

.caseTitleLink {
    font-weight: 600;
    cursor: pointer;
}

.riskPill {
    display: none;
    grid-column: 6/7;

    @include tabletAndAbove {
        display: inline-block;
        grid-column: 7/8;
    }
}

.titleLink {
    font-weight: 600;
}

.overdueHover{
    &:hover {
        border-left-style: solid;
        border-left-width: 4px;
        background-color: $negativeBarListItemBackgroundColor;
        border-color: $negativeBarListItemBorderColor;
    }
}

.overflowTooltip{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
}

.pendingHover{
    &:hover {
        border-left-style: solid;
        border-left-width: 4px;
        background-color: $infoBarListItemBackgroundColor;
        border-color: $infoBarListItemBorderColor;
    }
}

.closedHover{
    &:hover {
        border-left-style: solid;
        border-left-width: 4px;
        background-color: $positiveBarListItemBackgroundColor;
        border-color: $positiveBarListItemBorderColor;
    }
}

.buttonize {
    height: 20px
}