@import 'styles/colors.scss';

.requestsTable {
    display: flex;
    flex-direction: column;
    gap: 45px;
    border-radius: 0;
    position: relative;
}

.tableWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;

}

.headerWrapper {
    position: sticky;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 2;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
}

.contentWrapper {
    position: relative;
    overflow: visible;

}

.tableFooter {
    display: none;
}

.titleListItem {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.titleListItemMultiTenant {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.titleCell {
    position: relative;
    text-transform: capitalize;
}

.draft {
    color: $tableInfoAlternativeTitleTextColor;
}

.approved {
    color: $tablePositiveTitleTextColor;
}

.withdrawn {
    color: $tableInfoTitleTextColor;
}

.categoryList {
    max-height: 45vh;
    overflow-y: auto;
}

.singleCategoryList {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}