@import 'styles/global.scss';

.due {
    &:hover {
        cursor: auto;
        opacity: 100%;
    }

    p {
        color: $inactiveTextColor;
    }
}
