@import 'styles/global.scss';

.asideLink {
    padding-top: 3px;
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: self-end;
}

.carousel {
    grid-column: 1/4;
    grid-row: 2/3;
}

.glancePanel {
    width: 100%;
}

.panelBody {
    display: grid;
    gap: 30px;
    grid-template-columns: max-content max-content 1fr;
    grid-template-rows: auto 1fr ;
    width: 100%;
}
