.addActivityQuestions {
    display: grid;
    gap: 22px 17px;
    grid-template-columns: 1fr 1fr 1fr;
}

.input {
    grid-column: 1 / 4;
}

.title {
    grid-column: 1 / 4;
}

.infoHollowButton {
    grid-column: 1 / 2;
}

.saveButton {
    grid-column: 1 / 4;
}

.saveAndCloseButton {
    grid-column: 3 / 4;
}

.dynamicEditor {
    grid-column: 1/4;
}

.formField {
    grid-column: 1/4;
}
