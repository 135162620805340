@import 'styles/global.scss';

.effectiveDate {
    grid-column: 3/4;
}

.name {
    grid-column: 1/3;
}

.policyInformation {
    display: grid;
    gap: 24px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: min-content;
}
