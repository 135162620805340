@import 'styles/global.scss';

.allMeToggle {
    align-content: center;
    display: grid;
    gap: 5px;
    grid-template-columns: 22px auto 62px;
    height: fit-content;
}

.bodyText {
    color: $inactiveTextColor;
    text-transform: capitalize;
}

.isActive {
    color: $activeColor;
}