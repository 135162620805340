@import 'styles/global.scss';

.panelHeader {
    display: grid;
    gap: 30px;
    width: 100%;
    z-index: 3;
    height: 58px;

    grid-template-columns: 1fr 1fr 1fr 2fr;
    @include mobileOnly {
        height: fit-content;
        grid-template-columns: 1fr 1fr;
    }
    grid-column: 1/2;
}

.panelBody {
    grid-template-columns: 1fr;
    grid-template-rows: 58px auto;
    z-index: 0;

    @include mobileOnly {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
    }
}

.table {
    grid-column: 1/2;
}

.emptyTable {
    height: 100%;
    text-align: center;
}
