@import 'styles/global.scss';

.dropdownItem {
    border-radius: 8px;
    padding: 15px;

    &:hover {
        background-color: $menuHoverBackgroundColor;
        cursor: pointer;
    }
}

.selected {
    background-color: $menuListItemActiveBackgroundColor;
}

.option {
    display: none;
}

.subItem {
    padding-left: 50px;
}