@import 'styles/global.scss';

.formsPanel {
    display: grid;
    grid-template-rows: max-content auto 25px;
    width: 100%;
    position: relative;
}

.loader {
    @include centerBoth;
    grid-template-columns: none;
}

.panelBody {
    max-height: 400px;

    @include tabletAndAbove {
        max-height: unset;
    }
}
