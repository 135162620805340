@import 'styles/global.scss';

.titleListItem {
    grid-template-columns: 1fr 1fr 1fr 40px;
    grid-template-rows: 20px;

    @include tabletAndAbove {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 40px;
    }
}

.titleListItemMultiTenant {
    grid-template-columns: 1fr 1fr 1fr 1fr 40px;
    grid-template-rows: 20px;

    @include tabletAndAbove {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 40px;
    }
}

.formCell {
    padding-left: 8%;
}

.titleLink {
    color: $textColor;
    cursor: pointer;
}