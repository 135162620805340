@import 'styles/global.scss';

.editButton {
    align-items: center;
    background-color: $editButtonBackgroundColor;
    display: flex;
    height: 29px;
    justify-content: center;
    padding: 8px;
    width: 100%;
}
