@import 'styles/global.scss';

.tab {
    color: $tabTextColor;
    background-color: $tabBackgroundColor;
    border-bottom: 2px solid $tabBorderColor;
    border-radius: 0;
    width: auto;

    &:hover {
        background-color: $tabHoverBackgroundColor;
    }

    &.active {
        border-color: $tabActiveBorderColor;
        color: $tabActiveTextColor;
    }
}
