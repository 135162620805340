@import 'styles/global.scss';

.documentManagement {
    display: flex;
    flex-direction: column;
    gap: $layoutGap;
    height: 100%;
    width: 100%;
}

@include mobileOnly {
    .documentManagement {
        flex-direction: column;
    }
}
