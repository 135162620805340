@import 'styles/global.scss';

.pill {
    @include normalFont;

    background-color: $pillBackgroundColor;
    padding: 4px 12px;
    border-radius: 16px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    white-space: nowrap;
}
