@import 'styles/global.scss';

.exportModalPanelBody {
    min-width: 350px;
    padding: 40px 70px;
}

.cancelSaveButtons {
    justify-content: center;
}

.panelTitle {
    justify-self: center;
}