@import 'src/styles/global.scss';

.hamburger {
    background-color: $hamburgerLineColor;
    height: 4px;
    cursor: pointer;
    outline: none;
    position: relative;
    user-select: none;
    width: 100%;

    &::after {
        border-top: 4px solid $hamburgerLineColor;
        content: '';
        height: 10px;
        left: 0;
        outline: none;
        position: absolute;
        top: -10px;
        user-select: none;
        width: 100%;
    }

    &::before {
        border-bottom: 4px solid $hamburgerLineColor;
        content: '';
        height: 10px;
        left: 0;
        outline: none;
        position: absolute;
        user-select: none;
        width: 100%;
    }
}
